import { EventData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Event from '../../../../../components/List/Item/Event';
import CMSView from '../../../../../components/View';
import List from '../../../../../containers/Espace/List';
import event from '../../../../../params/evenement.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const PageAutomatisationEvenements: FC<
  PageProps & EspaceProps & UserProps & { pageContext: { tab?: number } }
> = ({
  espace,
  location,
  user,
  pageContext: { tab },
  params: { espaceId },
}) => {
  const { t } = useTranslation();

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Event}
          itemPathnamePrefix={`/espaces/${espaceId}/automatisation/evenements/`}
          model={
            new EventData({
              espaceId,
              orderDirection: 'desc',
              orderField: 'createdAt',
              params: event,
            })
          }
          search={location.search}
          tabIndex={tab}
          title={t('evenements.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageAutomatisationEvenements);
