import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { FC } from 'react';

const DateSC = styled.div`
  background: #e4e7ed;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: hsla(0, 0%, 0%, 0.7) !important;
  .day {
    font-size: 16px;
    line-height: 18px;
    display: block;
  }
  .month {
    font-size: 12px;
    line-height: 14px;
    display: block;
  }
  .time {
    font-size: 9px;
    line-height: 9px;
  }
`;

const ListItemDate: FC<{ className?: string; date: string }> = ({
  className,
  date,
}) => (
  <DateSC className={className}>
    <span className="day">{dayjs(date).format('D')}</span>
    <span className="month">{dayjs(date).format('MMM')}</span>
    <span className="time">{dayjs(date).format('HH:mm')}</span>
  </DateSC>
);

export default ListItemDate;
